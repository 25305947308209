import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Button, Divider, TextField, createTheme, ThemeProvider } from '@mui/material'

import 'styles/pages/editAppPage.scss'
import { CustomAttribute, InputField, SelectorField } from 'components/generic'
import { appController, tagController } from 'controllers'
import { appInterface } from 'interfaces'
import { ISelectorState } from 'interfaces/selector'
import { appHelper } from 'helpers'
import { announcementOptions, categoryOptions } from 'utils/appConsts'
import Links from 'components/generic/links'
import { ILink } from 'interfaces/appInterface'

export default function EditAppPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedCategories, setSelectedCategories] = useState<ISelectorState[]>()
  const [selectedTags, setSelectedTags] = useState<ISelectorState[]>()
  const [tagOptions, setTagOptions] = useState<ISelectorState[]>()
  const uuid: string | undefined = params.uuid
  const [newAppData, setNewAppData] = useState<appInterface.IUpdateApp>()
  const [existingApp, setExistingApp] = useState<appInterface.IUpdateApp>()
  const { enqueueSnackbar } = useSnackbar()
  const [isUpdating, setUpdating] = useState(false)
  const [customAttributes, setCustomAttributes] = useState<appInterface.ICAttributes[]>([])
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    setNewAppData({
      ...newAppData,
      [e.target.name]: value,
    })
  }

  function handleChangeAnnouncement(e: string) {
    setNewAppData({
      ...newAppData,
      announcement: {
        ...newAppData?.announcement,
        text: e,
      },
    })
  }

  async function getAppById() {
    try {
      if (!uuid) return navigate('/home')
      const appData: appInterface.IApp = await appController.getAppById(uuid)
      const { _id, ...fixedAppData } = appData
      setNewAppData({ ...fixedAppData })
      setExistingApp({ ...fixedAppData })
      setSelectedCategories(fixedAppData.categories.map((c) => ({ label: c, value: c })))
      setCustomAttributes(fixedAppData.customAttributes ?? [])
      setSelectedTags(fixedAppData.tags?.map((t) => ({ label: t, value: t })))
      setIsLoading(false)
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  async function fetchTags() {
    const tagOptionsFetch = await tagController.getTags()
    setTagOptions(tagOptionsFetch.map((tag) => ({ label: tag, value: tag })))
  }

  useEffect(() => {
    const fixTags = async () =>
      setNewAppData({
        ...newAppData,
        tags: (await appHelper.filterSelectorArray(selectedTags)) || [],
      })
    if (selectedTags) fixTags()
  }, [selectedTags])

  useEffect(() => {
    const fixCategories = async () =>
      setNewAppData({
        ...newAppData,
        categories: (await appHelper.filterSelectorArray(selectedCategories)) || [],
      })
    if (selectedCategories) fixCategories()
  }, [selectedCategories])

  async function updateAppById() {
    try {
      if (!uuid) return navigate('/home')
      setUpdating(true)
      if (newAppData) await appController.updateAppById(uuid, newAppData)
      enqueueSnackbar('App suggestion updated successfully', {
        variant: 'success',
      })
      navigate(`/app/${uuid}`)
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    setUpdating(false)
  }

  function addCustomAttribute(attributeData: appInterface.ICAttributes) {
    setCustomAttributes([...customAttributes, attributeData])
  }

  function removeCustomAttribute(attributeData: appInterface.ICAttributes) {
    setCustomAttributes(customAttributes.filter((a) => a.name !== attributeData.name))
  }

  useEffect(() => {
    fetchTags()
    getAppById()
  }, [])

  useEffect(() => {
    setNewAppData((prev) => ({
      ...prev,
      customAttributes,
    }))
  }, [customAttributes])

  function validateForm(): boolean {
    const errors: Record<string, string> = {}
    
    if (!newAppData?.title?.trim()) {
      errors.title = 'Title is required'
    }
    if (!newAppData?.description?.trim()) {
      errors.description = 'Description is required'
    }
    if (!newAppData?.sellerName?.trim()) {
      errors.sellerName = 'Vendor name is required'
    }
    if (!selectedCategories?.length) {
      errors.categories = 'At least one category is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#61dafb',
      },
      background: {
        paper: '#1d2026',
        default: '#282c34',
      },
      text: {
        primary: '#c9c9c9',
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              color: '#c9c9c9',
            },
          },
        },
      },
    },
  })

  const selectStyles = {
    input: (base: any) => ({
      ...base,
      color: '#c9c9c9',
    }),
    control: (base: any) => ({
      ...base,
      background: '#1d2026',
      borderColor: 'rgba(97, 218, 251, 0.2)',
    }),
    menu: (base: any) => ({
      ...base,
      background: '#1d2026',
      border: '1px solid rgba(97, 218, 251, 0.2)',
    }),
    option: (base: any, state: { isFocused: boolean }) => ({
      ...base,
      backgroundColor: state.isFocused ? 'rgba(97, 218, 251, 0.1)' : '#1d2026',
      color: '#c9c9c9',
      '&:hover': {
        backgroundColor: 'rgba(97, 218, 251, 0.1)',
      },
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#c9c9c9',
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: 'rgba(97, 218, 251, 0.1)',
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: '#c9c9c9',
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: '#c9c9c9',
      '&:hover': {
        backgroundColor: 'rgba(97, 218, 251, 0.2)',
        color: '#fff',
      },
    }),
  }

  if (isLoading || !newAppData) {
    return (
      <ThemeProvider theme={darkTheme}>
        <div className="loading-container">
          <CircularProgress />
          <p>Loading app details...</p>
        </div>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <section className="edit-app-page">
        <Paper elevation={2} className="edit-app-container">
          <div className="header">
            <h1>Edit App: {newAppData.title}</h1>
            <img
              className="app-image"
              referrerPolicy="no-referrer"
              src={newAppData.imageUrl}
              alt={newAppData.title}
            />
          </div>

          <div className="form-section">
            <h2>Basic Information</h2>
            <TextField
              fullWidth
              name="title"
              label="App Title"
              value={newAppData?.title}
              onChange={handleChange}
              error={!!formErrors.title}
              helperText={formErrors.title}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              name="description"
              label="Description"
              value={newAppData?.description}
              onChange={handleChange}
              error={!!formErrors.description}
              helperText={formErrors.description}
              multiline
              rows={4}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              name="sellerName"
              label="Vendor Name"
              value={newAppData?.sellerName}
              onChange={handleChange}
              error={!!formErrors.sellerName}
              helperText={formErrors.sellerName}
              required
              margin="normal"
            />
          </div>

          <Divider className="section-divider" />

          <div className="form-section">
            <h2>Categories & Tags</h2>
            <div className="selector-field-container">
              <SelectorField
                name="categories"
                isMulti
                label="Categories *"
                options={categoryOptions}
                value={selectedCategories}
                handleChange={(categories: ISelectorState[]) => setSelectedCategories(categories)}
                styles={selectStyles}
              />
              {formErrors.categories && (
                <div className="error-text">{formErrors.categories}</div>
              )}
            </div>
            <SelectorField
              isMulti
              name="tags"
              label="Tags"
              value={selectedTags}
              options={tagOptions}
              creatable
              handleChange={(tags: ISelectorState[]) => setSelectedTags(tags)}
              styles={selectStyles}
            />
          </div>

          <Divider className="section-divider" />

          <div className="form-section">
            <h2>Announcement</h2>
            <SelectorField
              name="announcementType"
              label="Announcement Type"
              options={announcementOptions}
              value={{
                label: newAppData?.announcement?.type || '',
                value: newAppData?.announcement?.type || '',
              }}
              handleChange={(announcement: { value: string; label: string }) => {
                setNewAppData({
                  ...newAppData,
                  announcement: {
                    ...newAppData?.announcement,
                    type: announcement.value,
                  },
                })
              }}
              styles={selectStyles}
            />
            <TextField
              fullWidth
              name="announcement"
              label="Announcement Text"
              value={newAppData?.announcement?.text}
              onChange={(e) => handleChangeAnnouncement(e.target.value)}
              multiline
              rows={2}
              margin="normal"
            />
          </div>

          <Divider className="section-divider" />

          <div className="form-section">
            <h2>Custom Attributes</h2>
            <CustomAttribute
              attributes={newAppData?.customAttributes}
              addNewAttribute={addCustomAttribute}
              removeAttribute={removeCustomAttribute}
            />
          </div>

          <Divider className="section-divider" />

          <div className="form-section">
            <h2>Links</h2>
            <Links
              links={newAppData?.links}
              addNewLink={(linkData) =>
                setNewAppData({ ...newAppData, links: [...(newAppData?.links as ILink[]), linkData] })
              }
              removeLink={(linkData) =>
                setNewAppData({
                  ...newAppData,
                  links: newAppData.links?.filter((link) => link.type !== linkData.type),
                })
              }
            />
          </div>

          <div className="actions">
            <Button
              variant="outlined"
              onClick={() => navigate(`/app/${uuid}`)}
              disabled={isUpdating}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={isUpdating || JSON.stringify(newAppData) === JSON.stringify(existingApp)}
              onClick={() => {
                if (validateForm()) {
                  setShowConfirmDialog(true)
                }
              }}
            >
              {isUpdating ? <CircularProgress size={24} /> : 'Save Changes'}
            </Button>
          </div>
        </Paper>

        <Dialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          PaperProps={{
            sx: {
              backgroundColor: '#1d2026',
              color: '#c9c9c9',
            }
          }}
        >
          <DialogTitle>Confirm Changes</DialogTitle>
          <DialogContent>
            Are you sure you want to save these changes?
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setShowConfirmDialog(false)}
            >
              Cancel
            </Button>
            <Button 
              onClick={() => {
                setShowConfirmDialog(false)
                updateAppById()
              }} 
              color="primary" 
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    </ThemeProvider>
  )
}
