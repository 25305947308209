import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import {
  AppPage,
  NotFoundPage,
  NewAppPage,
  LoginPage,
  DashboardPage,
  ListPage,
  PublicLists,
  SearchPage,
  AllUsersPage,
  AllAppsPage,
  AllListsPage,
  UserPage,
  EditAppPage,
  AllPendingAppChangePage,
  PendingAppChangePage,
  PendingPlatforms,
  PendingPlatformsChangePage,
  Attributes,
  APILogsPage,
} from 'pages'
import { adminController } from 'controllers'
import { Menu } from 'components/generic'
import 'styles/App.scss'
import AppReports from 'pages/AppReports'
import AppReportsPage from 'pages/AppReportsPage'
import Logs from 'pages/Logs'
import { SuggestedSubscriptionsPage } from 'pages/Subscriptions/SuggestedSubscriptionsPage'
import { FeaturedListsPage } from 'pages/FeaturedLists/FeaturedListsPage'
import { checkAuthState, setLoggedOut } from 'utils/axiosInstance'

interface LoginProps {
  isLoggedIn: boolean
  setIsLoggedIn: (state: boolean) => void
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(checkAuthState())
  const navigate = useNavigate()
  const location = useLocation()

  const executeLogin = (state: boolean | ((prevState: boolean) => boolean)) => {
    const newState = typeof state === 'function' ? state(isLoggedIn) : state
    setIsLoggedIn(newState)
    setLoggedOut(!newState) // Sync with axios instance
    sessionStorage.setItem('auth_state', newState ? 'logged_in' : 'logged_out')
    if (!newState) {
      sessionStorage.removeItem('userData')
    }
  }

  const refreshUser = async () => {
    try {
      // Skip refresh if we're explicitly logged out or on the login page
      const authState = sessionStorage.getItem('auth_state')
      if (authState === 'logged_out' || location.pathname === '/login') {
        return
      }

      const userFetch = await adminController.getAdminData()

      // Handle different response scenarios
      if (userFetch === null) {
        return // Don't change login state if we get a successful null response
      }

      if (!userFetch && !['/login', '/register', '/'].some((cur) => location.pathname === cur)) {
        navigate('/login')
      }

      if (!userFetch && isLoggedIn) {
        executeLogin(false)
      } else if (userFetch) {
        executeLogin(true)
        sessionStorage.setItem('userData', JSON.stringify(userFetch))
      }
    } catch (error: any) {
      // Only log out on auth errors
      if (error.response?.status === 401 || error.response?.status === 403) {
        executeLogin(false)
        if (!['/login', '/register', '/'].includes(location.pathname)) {
          navigate('/login')
        }
      }
      // Don't change login state for other types of errors
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('auth_state') !== 'logged_out' && location.pathname !== '/login') {
      refreshUser()
    }
  }, [location.pathname])

  // Add effect to check auth state on mount and auth storage changes
  useEffect(() => {
    const handleStorageChange = () => {
      const newAuthState = checkAuthState()
      if (newAuthState !== isLoggedIn) {
        setIsLoggedIn(newAuthState)
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [isLoggedIn])

  return (
    <div className='App'>
      <header>
        <Menu isLoggedIn={isLoggedIn} setIsLoggedIn={executeLogin} />
      </header>
      <Routes>
        <Route path='/' element={<DashboardPage isLoggedIn={isLoggedIn} />} />
        <Route path='/users' element={<AllUsersPage isLoggedIn={isLoggedIn} />} />
        <Route path='/users/:uuid' element={<UserPage isLoggedIn={isLoggedIn} />} />
        <Route path='/lists' element={<AllListsPage isLoggedIn={isLoggedIn} />} />
        <Route path='/apps' element={<AllAppsPage isLoggedIn={isLoggedIn} />} />
        <Route path='/apps/pending' element={<AllAppsPage isLoggedIn={isLoggedIn} pending />} />
        <Route
          path='/apps/pending-changes'
          element={<AllPendingAppChangePage isLoggedIn={isLoggedIn} />}
        />
        <Route
          path='/apps/pending-changes/:uuid'
          element={<PendingAppChangePage isLoggedIn={isLoggedIn} />}
        />
        <Route path='/apps/attributes' element={<Attributes isLoggedIn={isLoggedIn} />} />
        <Route
          path='/apps/pending-platforms'
          element={<PendingPlatforms isLoggedIn={isLoggedIn} />}
        />
        <Route
          path='/apps/pending-platforms/:uuid'
          element={<PendingPlatformsChangePage isLoggedIn={isLoggedIn} />}
        />
        <Route path='/apps/app-reports' element={<AppReports isLoggedIn={isLoggedIn} />} />
        <Route
          path='/apps/app-reports/:uuid'
          element={<AppReportsPage isLoggedIn={isLoggedIn} />}
        />
        <Route path='/suggested-subscriptions' element={<SuggestedSubscriptionsPage />} />
        <Route path='/featured-lists' element={<FeaturedListsPage />} />
        <Route path='/404' element={<NotFoundPage />} />
        <Route path='/new-app' element={<NewAppPage />} />
        <Route path='/app/:uuid' element={<AppPage />} />
        <Route path='/search' element={<SearchPage />} />
        <Route path='/public-lists' element={<PublicLists />} />
        <Route path='/list/:uuid' element={<ListPage />} />
        <Route path='/app/:uuid/edit' element={<EditAppPage />} />
        <Route
          path='/login'
          element={<LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={executeLogin} />}
        />
        {/* <Route path="/register" element={<RegisterPage />} /> */}
        <Route path='/logs' element={<Logs isLoggedIn={isLoggedIn} />} />
        <Route path='/api-logs' element={<APILogsPage isLoggedIn={isLoggedIn} />} />
      </Routes>
    </div>
  )
}

export default App
