import React, { useEffect, useState } from 'react'
import 'styles/components/generic/links.scss'
import { BsXCircle } from 'react-icons/bs'
import { appInterface } from 'interfaces'
import InputField from './inputField'
import { ILink } from 'interfaces/appInterface'
import SelectorField from './selectorField'
import { linksOptions } from 'utils/appConsts'
import { ISelectorState } from 'interfaces/selector'
import { Button } from '@mui/material'

interface CustomLinksProps {
  links?: appInterface.ILink[]
  addNewLink: (attr: ILink) => void
  removeLink: (attr: ILink) => void
}

function Links({ links, addNewLink, removeLink }: CustomLinksProps) {
  const [isAdding, setIsAdding] = useState<boolean>(false)
  const [newLink, setNewLink] = useState<appInterface.ILink>({
    link: '',
    type: '',
  })
  const [selectedLink, setSelectedLink] = useState<ISelectorState>()
  const [selectedLinks, setSelectedLinks] = useState<any>([])

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    setNewLink({
      ...newLink,
      [e.target.name]: value,
    })
  }

  function clear() {
    setNewLink({ type: '', link: '' })
    setIsAdding(false)
  }

  useEffect(() => {
    setNewLink({ ...newLink, type: selectedLink?.value || '' })
  }, [selectedLink])

  return (
    <div className='linksWrapper'>
      <span className='linkTitle'>Links</span>
      <div className='linkWrapper'>
        {links?.map((link) => (
          <div key={link.link} className='linkBox'>
            <span className='linkName'>{link.type}</span>
            <span className='linkValue'>{link.link}</span>
            <BsXCircle className='deleteLink' onClick={() => removeLink(link)} />
          </div>
        ))}
      </div>
      {isAdding ? (
        <div>
          <SelectorField
            name='linkType'
            label='Link Type'
            value={selectedLink}
            options={linksOptions}
            handleChange={(link: any) => {
              setSelectedLink(link)
              setSelectedLinks([...selectedLinks, link])
            }}
          />
          <InputField
            name='link'
            label='Link'
            value={newLink.link}
            handleChange={(e) => handleChange(e)}
          />
          <div className='buttonBox'>
            <button
              type='button'
              onClick={() => {
                clear()
                addNewLink(newLink)
              }}
              className='cta'
            >
              Add Link
            </button>
            <button type='button' onClick={clear} className='cta danger'>
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={() => setIsAdding(true)}
        >
          Add Link
        </Button>
      )}
    </div>
  )
}

export default Links
