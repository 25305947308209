import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { getAuthInstance } from 'utils/axiosInstance'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
} from '@mui/material'
import { format } from 'date-fns'
import 'styles/pages/apiLogsPage.scss'

interface APILogEntry {
  requestId: string
  timestamp: Date
  method: string
  path: string
  queryParams: Record<string, any>
  requestHeaders: Record<string, string>
  requestBody?: Record<string, any>
  requestSize?: number
  userAgent?: string
  ip: string
  userId?: string
  statusCode: number
  responseTime: number
  responseSize?: number
  error?: {
    message: string
    stack?: string
  }
}

interface APILogPagination {
  total: number
  page: number
  limit: number
  pages: number
}

interface APILogResponse {
  data: APILogEntry[]
  pagination: APILogPagination
}

function APILogsPage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [logs, setLogs] = useState<APILogEntry[]>([])
  const [pagination, setPagination] = useState<APILogPagination>({
    total: 0,
    page: 1,
    limit: 100,
    pages: 0,
  })
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const axiosInstance = getAuthInstance(
    process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001',
  )

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  const fetchLogs = async (page: number = 1, limit: number = 100) => {
    try {
      const response = await axiosInstance.get<APILogResponse>('/api-logs', {
        params: {
          page,
          limit,
        },
      })
      setLogs(response.data.data)
      setPagination(response.data.pagination)
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Failed to fetch API logs', { variant: 'error' })
    }
  }

  const clearLogs = async () => {
    try {
      await axiosInstance.delete('/api-logs')
      enqueueSnackbar('Logs cleared successfully', { variant: 'success' })
      fetchLogs()
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Failed to clear logs', { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchLogs()
  }, [])

  const handleChangePage = (_event: unknown, newPage: number) => {
    fetchLogs(newPage + 1, pagination.limit)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10)
    fetchLogs(1, newLimit)
  }

  return (
    <div className='apiLogsPage'>
      <div className='header'>
        <h1>API Logs</h1>
        <Button variant='contained' color='error' onClick={clearLogs} className='clearButton'>
          Clear All Logs
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Path</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Response Time (ms)</TableCell>
              <TableCell>IP</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log) => (
              <TableRow key={log.requestId}>
                <TableCell>{format(new Date(log.timestamp), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                <TableCell>{log.method}</TableCell>
                <TableCell>{log.path}</TableCell>
                <TableCell>{log.statusCode}</TableCell>
                <TableCell>{log.responseTime}</TableCell>
                <TableCell>{log.ip}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={pagination.total}
          page={pagination.page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </TableContainer>
    </div>
  )
}

export default APILogsPage
